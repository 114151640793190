import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ilovenellie';
  imageNumber: number;
  audio: any;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.audio = document.getElementById('audioElement');
    this.chooseNewMedia();
  }

  chooseNewMedia() {
    this.imageNumber = Math.floor((Math.random() * 12) + 1);
    switch (this.imageNumber) {
      case 1:
        this.audio.src = "../assets/recordings/8801 Bethpage Ln 2.m4a"
        break;

      case 2:
        this.audio.src = "../assets/recordings/8801 Bethpage Ln 3.m4a"
        break;
  
      case 3:
        this.audio.src = "../assets/recordings/8801 Bethpage Ln 4.m4a"
        break;
  
      case 4:
        this.audio.src = "../assets/recordings/8801 Bethpage Ln 5.m4a"
        break;
  
      case 5:
        this.audio.src = "../assets/recordings/8801 Bethpage Ln 6.m4a"
        break;
  
      case 6:
        this.audio.src = "../assets/recordings/8801 Bethpage Ln 7.m4a"
        break;
  
      case 7:
        this.audio.src = "../assets/recordings/8801 Bethpage Ln 8.m4a"
        break;
  
      case 8:
        this.audio.src = "../assets/recordings/8801 Bethpage Ln.m4a"
        break;
  
      case 9:
        this.audio.src = "../assets/recordings/New Recording 2.m4a"
        break;
  
      case 10:
        this.audio.src = "../assets/recordings/New Recording 3.m4a"
        break;
  
      case 11:
        this.audio.src = "../assets/recordings/New Recording 4.m4a"
        break;
  
      case 12:
        this.audio.src = "../assets/recordings/New Recording.m4a"
        break;
    
      default:
        break;
    }
  }
}
